/* You can add global styles to this file, and also import other style files */

@import "./assets/sass/variables.scss";
@import "./assets/sass/fontStyle.scss";
@import "./assets/sass/custom-theme.scss";
@import "./assets/sass/common.scss";
@import '~@aws-amplify/ui-angular/theme.css';



/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

  
.amplify-button[data-variation='primary'] {
    background: $color-corp; 
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    background-color: $color-corp; 
    border-radius: 4px;
    cursor: pointer;
    color:$color-light; 
    border:none; 
  }

  button.amplify-button.amplify-field-group__control{
  //  background:black !important; 
  display: none;
  }

  .amplify-tabs {
    display: none;
  }

  amplify-authenticator{
    data-amplify-container{
        margin-top:25px; 
        padding: 50px; 
    }
    [data-amplify-authenticator] [data-amplify-container]{
        margin-top:25px; 
        padding: 50px; 
    }

  }    
  data-amplify-container{
    margin-top:25px; 
    padding: 50px; 
}

.error-snackbar {
  background-color: red;
  color: black;
  font-weight: 500;
  font-size: large;
}