@import 'variables.scss';

html,
body {
    height: 100%;
}

body {
    font-family: $font-corp;
    font-weight: 400;
    font-size: 14px;
    color: $color-dark;
    background-color: $color-gray-light;
    margin: 0;
}
.margin-l-20{
    margin-left: 20px;
}
//ESTRUCTURA
.main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;

    .content {
        display: flex;
        width: 91%;
        flex-direction: column;
        padding: 16px 24px 41px;
        // max-width: calc(100% - 240px);
        max-width: 83.5vw;
    }
}

.container {
    width: auto;
    align-items: center;
    position: relative;
    max-width: calc(100vw - 157px);
}


.row {
    display: flex;
    width: 100%;

    &.row-between {
        justify-content: space-between;
    }

    &.row-column {
        flex-direction: column;
    }
}

//LINK-BUTTONS
a {
    text-decoration: none;
}

.link {
    text-decoration: none;
    color: $color-dark;
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $color-dark;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}

.mat-button {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    transition: all 0.5s ease;

    .text {
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .icon {
        padding-left: 4px;
    }

    &.mat-primary {
        color: $color-corp;
    }
    &:not(.mat-button-disabled){
        &:hover {
            background-color: $color-hover;
            color: $color-light;
            span{
                color: $color-light!important;
            }
        }
    }
    
}
.mat-button.mat-button-disabled{
    pointer-events: none;
    cursor: not-allowed;
}

.mat-raised-button,
.mat-flat-button {
    box-shadow: none;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
    transition: all 0.5s ease;

    mat-icon {
        padding-left: 4px;
    }

    &:hover {
        background-color: $color-hover;
        color: $color-light;
    }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $color-corp;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: $color-corp;
}
button.mat-menu-item{
    display: flex;
    align-items: center;
    .icon{
        padding-right: 10px;
    }
}
//TABLA
mat-paginator{
    .mat-select-value{
        max-width: 14%;
    }
}
.mat-paginator-range-label{
    margin: 0 22px 0 14px!important
}
table th.noSort {
    .mat-sort-header-arrow {
        display: none !important;
    }
}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
    min-width: 120px;
    padding-left: 20px!important;
}
.header-table{
    text-align: left;
}
.mat-sort-header-content{
    text-align: left;
}
.mat-sort-header[aria-sort='none'] {
    .mat-sort-header-arrow {
        opacity: 0.54 !important;
    }
}

.mat-sort-header-container {
    position: relative;
    padding-right: 10px;
}

.mat-sort-header-arrow {
    transform: translateY(0%) !important;
}

.sidebar-shrink {
    flex-shrink: 0;
    z-index: 2;
}
.table-container-main{
    position: relative;
    .mat-paginator{
        position: absolute;
        width: 100%;
        bottom: 20px;
        background: transparent;
        padding-bottom: 10px;
        z-index: 2;
        height: 50px;
    }
    .table-container {
        position: relative;
        overflow: auto;
        background-color: white;
        padding-bottom: 60px;
        white-space: nowrap;
    }
}

.table-container {
    position: relative;
    overflow: scroll;
    background-color: white;

    &.acciones--1 {
        padding-right: 61px;
    }

    &.acciones--2 {
        padding-right: 95px;
    }

    // &.acciones--3 {
    //     padding-right: 150px;
    // }
}

.tableAccions {
    background: white;
    position: absolute;
    right: 0;
    top: 0;
    width: 120px !important;
    padding: 0;
    padding-bottom: 54px;

    &.acciones--1 {
        width: 60px !important;
    }

    &.acciones--2 {
        width: 120px !important;
    }

    &.acciones--3 {
        width: 180px !important;
    }

    thead {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgba(0, 0, 0, .12);
        height: 54.2px;
        margin: 0;
        background: inherit;
        display: flex;
        align-items: center;
        width: 103%;
        justify-content: space-between;
        margin: 0 0 0 -3%;

        th {
            color: rgba(0, 0, 0, 0.54);
            text-align: center;
            width: 100%;
        }
    }

    tbody {
        display: flex;
        align-items: center;
        width: 103%;
        margin: 0 0 0 -3%;
        justify-content: space-between;
        flex-direction: column;

        tr {
            height: 48px;
            display: flex;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgba(0, 0, 0, .12);
            align-items: center;
            width: 100%;
            justify-content: space-between;

            td {

                text-align: center;
                width: 100%;

                .icon {
                    color: $color-corp;
                    cursor: pointer;
                    opacity: 0.9;
                    transition: all 0.5s ease;

                    &:hover {
                        color: $color-hover;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.TableMain{
    padding-right: 60px;
}
.tableMaster {
    background: white;
    position: absolute;
    right: 0;
    top: 0;
    width: 120px !important;
    padding: 0;
    padding-bottom: 54px;

    &.acciones--1 {
        width: 60px !important;
    }

    &.acciones--2 {
        width: 120px !important;
    }
    .header-table{
        text-align: center;
        color: black;
    font-weight: bold;
    }
    thead {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgba(0, 0, 0, .12);
        height: 54px;
        margin: 0;
        background: inherit;
        display: flex;
        align-items: center;
        width: 103%;
        justify-content: space-between;
        margin: 0 0 0 -3%;

        th {
            color: rgba(0, 0, 0, 0.54);
            text-align: center;
            width: 100%;
        }
    }

    tbody {
        display: flex;
        align-items: center;
        width: 103%;
        margin: 0 0 0 -3%;
        justify-content: space-between;
        flex-direction: column;

        tr {
            height: 48px;
            display: flex;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgba(0, 0, 0, .12);
            align-items: center;
            width: 100%;
            justify-content: space-between;

            td {

                text-align: center;
                width: 100%;

                .icon {
                    color: $color-corp;
                    cursor: pointer;
                    opacity: 0.9;
                    transition: all 0.5s ease;

                    &:hover {
                        color: $color-hover;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

//FILTROS
.mat-datepicker-toggle-default-icon {
    color: red;
}

.mat-calendar-body-selected {
    background-color: red;
    color: white;
}

.mat-calendar-body-in-range::before {
    background-color: rgb(240, 199, 199);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #F1948A
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $color-corp;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $color-corp;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $color-corp;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $color-corp;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($color-corp, .54);
}

//snack-bar
.mat-snack-bar-container {
    color: black;
    background: #84B6F7;
    // background: #cce5ff;
}

//ESTILOS DEL CAROUSEL 
.carousel {
    >button {
        transition: all 0.5s ease;

        &:first-of-type {
            left: 5px !important;
        }

        &:last-of-type {
            right: 10px !important;
        }

        &:hover {
            background-color: rgba($color-corp, .4);
            color: white;
        }
    }

    .carousel-list {
        left: 50px;
    }

    .carousel-slide .ng-star-inserted {
        width: 50%;
        @media (max-width: 1300px) {
            width: 91%;
        }

        // @media (max-width: 600px) {
        //     width: 76%;
        // }

        .test{
            background-color: red;
        }
    }

    .carousel-slide-content {
        width: 50%;
        left: auto;
        position: absolute;
        z-index: 1;
        right: -100%;

        @media (max-width: 1300px) {
            right: 0;
            top: 100%;
            width: 95%;
        }

    @media (max-width: 768px){
        height: 40vh!important;
    }

    @media (max-width: 992px){
        height: 68vh!important;
    }
}
@media (max-width: 1300px) {
    height: 70vh!important;
    
}

}
.content-spinner{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d8d8d89e;
    -webkit-backdrop-filter: saturate(1100%) blur(2px);
    backdrop-filter: saturate(1100%) blur(2px);
    z-index: 1;
}
//Estilos modales
 
.mat-dialog-container {
    max-width: 100vw;
    width: 100vw;
    @media (max-width: $tablet) {
        max-width: 70vw;
    }
    .modal-bigger-size{
        max-width: 100vw!important;
    }
    .modal-title-close {
        max-width: 50vw;
        display: flex;
        justify-content: space-between;
    }

    >.ng-star-inserted {
        position: relative;
    }

    .mat-dialog-title {
        font-size: 25px;
    }

    .mat-dialog-content {
        max-width: 50vw;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        min-width: 600px;
        @media (max-width: $desktopSmall) {
            min-width: auto;
        }
        >.ng-star-inserted {
            width: calc(100% / 3);

            @media (max-width: $desktopSmall) {
                width: calc(100% / 2);
            }
            @media (max-width: $mobile) {
                width: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    .hide-access.close {
        font-size: 0;
        background: none;
        color: transparent;
        width: 30px;
        height: 30px;
        max-width: 30px;
        max-height: 30px;
        min-width: 30px;
        min-height: 30px;
        padding: 0;
        position: relative;

        


    }

    .mat-dialog-actions {
        justify-content: center;

        .mat-button-focus-overlay {
            // display: none;
        }

        .mat-form-field-wrapper {
            width: 180px !important;
            position: relative;
            margin: 17px 7px 0px 8px;
        }
    }

    app-filtro-date-only {
        .mat-form-field-label-wrapper {
            overflow: visible;
        }

        label {
            left: -9px;
            transform: translateY(0.80625em) scale(0.75);
        }

        input {
            margin-left: -10px;
        }

        mat-label {
            font-size: 18px;
        }

        .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label {
            font-size: 14px;
        }
    }

}

.box_cups_input{
    mat-icon{
        font-size: 20px;
        max-width: 20px;
        max-height: 20px;
        position: absolute;
        color: $color-corp;
        right: 2px;
        top: 50%;
        transform: translate(0,-50%);
    }
}

mat-form-field {
    margin: 0 !important;

    .mat-form-field-wrapper {
        width: 180px !important;
        position: relative;
        margin: 8px 7px 0px 8px;
    }
}

.mat-simple-snackbar{
    .mat-button{
        position: relative;
        font-size: 0;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        background: $color-corp;
        &:before{
            content: "";
            position: absolute;
            background: $color-light;
            // Este display y border son para solucionar el bug de la 'X' en modales
            display: block;
            border: 2px;
            width: 2px;
            height: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);  
        }
        &:after{
            content: "";
            position: absolute;
            background: $color-light;
            width: 2px;
            height: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:hover,&:focus{
            background: $color-hover;
        }
    }
}

.content_multiselect__container-icon{
    mat-icon{
        cursor: pointer;
    }
}

app-details-page {
    .mat-tab-label.mat-tab-label-active {
        color: white;
        opacity: 1 !important;
      }
    .mat-ink-bar {
    background-color: #3f51b5 !important;
    }
}

:host ::ng-deep .mat-paginator-range-actions {
    // button:nth-of-type(1) {
    //     padding-left: 56px;
    // }
    
    // button:nth-of-type(2) {
    //     padding-left: 32px;
    // }
    
    button:nth-of-type(3) {
        padding-left: 32px;
    }

    button:nth-of-type(4) {
        padding-left: 22px;
    }
}

.amplify-button--small{
    display: none;
}
.amplify-heading{
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0 0 0!important;
    letter-spacing: 1px;
    font-size: 18px;
}

//SPINNER
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #f44336;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #f44336 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

 
  .spinner {
     width: 30px;
     height: 30px;
     display: grid;
     border-radius: 50%;
     -webkit-mask: radial-gradient(farthest-side,#0000 40%,#ff4747 41%);
     background: linear-gradient(0deg ,rgba(255,71,71,0.5) 50%,rgba(255,71,71,1) 0) center/2.5px 100%,
          linear-gradient(90deg,rgba(255,71,71,0.25) 50%,rgba(255,71,71,0.75) 0) center/100% 2.5px;
     background-repeat: no-repeat;
     animation: spinner-d3o0rx 1s infinite steps(12);
  }
  
  .spinner::before,
  .spinner::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  
  .spinner::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }
  
  @keyframes spinner-d3o0rx {
     100% {
        transform: rotate(1turn);
     }
  }

  app-filtro-date-variable {
      .filter_variable_container {
        .days_container {
            padding-right: 16px;
                mat-form-field {
        
                    .mat-form-field-wrapper {
                        width: 80px !important;
                        text-align: center;
                    }
                }
            }
        
            .months_container {
                padding-right: 16px;
                mat-form-field {
        
                    .mat-form-field-wrapper {
                        width: 80px !important;
                        text-align: center;
                    }
                }
            }
        
            .years_container {
                padding-right: 16px;
                mat-form-field {
        
                    .mat-form-field-wrapper {
                        width: 80px !important;
                        text-align: center;
                    }
                }
            }
      }
  }

.close {

    ::before {
        content: "";
        position: absolute;
        background: $color-gray--dark;
        width: 2px;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    ::after {
        content: "";
        position: absolute;
        background: $color-gray--dark;
        width: 2px;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
    }
}