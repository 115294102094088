$font-corp: 'Acciona-regular', sans-serif;
$font-bold: 'Acciona-bold', sans-serif;
$font-italic: 'Acciona-lightItalic', sans-serif;
$font-light: 'Acciona-light', sans-serif;

// colores y tipografias

$color-corp: #e71818;
$color-dark: black;
$color-dark2: #1d1d1b;
$color-light: white;
$color-gray-light: #f8f7f7;
$color-gray--medium: #ececec;
$color-gray--dark: #858585;
$color-gray--dark2: #6c6c6c;
$color-hover: #dc3b2f;

// responsive
//@media (max-width: $variable) {}

$desktopSmall: 1300px;
$tablet: 992px;
$mobile: 768px;
