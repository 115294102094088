@font-face{ 
    font-family:Acciona-regular;
    src: url('../fonts/acciona-regular.woff2') format('woff2'),
    url('../fonts/acciona-regular.woff') format('woff'),
    url('../fonts/acciona-regular.svg#Acciona-regular') format('svg');
    font-weight:400;
}

@font-face{

    font-family:Acciona-bold;
    src: url('../fonts/acciona-bold.woff2') format('woff2'),
    url('../fonts/acciona-bold.woff') format('woff'),
    url('../fonts/acciona-bold.svg#Acciona-bold') format('svg');
    font-weight:600;
}

@font-face{

    font-family:Acciona-light;
    src: url('../fonts/acciona-light.woff2') format('woff2'),
    url('../fonts/acciona-light.woff') format('woff'),
    url('../fonts/acciona-light.svg#Acciona-light') format('svg');
    font-weight:100;
}

@font-face{

    font-family:Acciona-lightItalic;
    src: url('../fonts/acciona-lightItalic.woff2') format('woff2'),
    url('../fonts/acciona-lightItalic.woff') format('woff'),
    url('../fonts/acciona-lightItalic.svg#Acciona-lightItalic') format('svg');
    font-weight:100;
}
